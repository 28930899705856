import Vue from 'vue'
import VueRouter from 'vue-router'
 
Vue.use(VueRouter)
// 解决路由重复报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/companyIndex',
    name: 'companyIndex',
    component: () => import('../views/company/companyIndex.vue')
  },
  {
    path: '/productIndex',
    name: 'productIndex',
    component: () => import('../views/product/productIndex.vue')
  },
  {
    path: '/productData',
    name: 'productData',
    component: () => import('../views/product/productData.vue')
  },
  {
    path: '/newsIndex',
    name: 'newsIndex',
    component: () => import('../views/news/newsIndex.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
