<template>
    <div class="nav" style="background-color: #f5f5f5;">
        <!-- 顶部导航 -->
        <div class="topNav_pc">
            <div class="topNav_pc_div">
                <div><img src="../assets/index/logo.png" /></div>
                <div>
                    <nav class="navbar">
                        <ul>
                            <li v-for="(item, index) in navItems" :key="index" @mouseover="showSubMenu(index)"
                                @mouseleave="hideSubMenu(index)">
                                <router-link :to="item.path" :class="{ active: activeIndex === index }">
                                    {{ item.name }}</router-link>
                                <ul v-if="item.children && showSub[index]">
                                    <li v-for="(subItem, subIndex) in item.children" :key="subIndex">
                                        <div @click="setSubItemName(subItem.routeId,subItem.path)"
                                            style="cursor:pointer">
                                            {{subItem.name}}
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="topNav_phone">
            <div class="topNav_phone_div">
                <div><img src="../assets/index/logo.png" /></div>
                <div @click="phone_navMenu=!phone_navMenu">
                    <i class="el-icon-s-order"></i>
                </div>
            </div>
        </div>
        <div class="showMenu" v-if="phone_navMenu==true">
            <el-menu default-active="1" class="el-menu-vertical-demo phoneMenu" @select="handleSelect">
                <template v-for="item in navItems">
                    <el-submenu v-if="item.children" :index="item.name" :key="item.name" class="phoneMenu_item">
                        <template slot="title">
                            {{ item.name }}
                        </template>
                        <el-menu-item v-for="subItem in item.children" :index="subItem.routeId" :key="subItem.routeId"
                            @click="goTo(subItem.path)">
                            {{ subItem.name }}
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="item.path" :key="item.path" @click="goTo(item.path)">
                        <router-link :to="item.path">{{ item.name }}</router-link>
                    </el-menu-item>
                </template>
            </el-menu>
        </div>
        <router-view></router-view>
        <!-- 底部导航 -->
        <div class="bottomNav_pc">
            <div class="bottomNav_pc_div">
                <div>
                    <div v-for="(item, index) in navItems" :key="index">
                        <router-link :to="item.path">{{ item.name }}</router-link>
                        <div v-for="(subItem, index) in item.children" :key="index">
                            <div @click="setSubItemName(subItem.routeId,subItem.path)" style="cursor:pointer">
                                {{subItem.name}}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div @click="toContactUs">联系我们</div>
                        <div>地址：{{companyInfo.address}}</div>
                        <div>电话：{{companyInfo.phone}}</div>
                        <div>邮箱：{{companyInfo.email}}</div>
                    </div>
                    <div><img :src="companyInfo.qrCode" /></div>

                </div>

                <div class="bottomNav_pc_div_2">
                    <div @click="goToIndustrialRecord" style="cursor: pointer;"> 鲁ICP备2024105942号</div>
                    <!-- <div> 鲁ICP备2024105942号</div> -->
                    <div> 版权所有：烟台帕特仑电子有限公司</div>
                </div>
            </div>

        </div>
        <div class="bottomNav_phone">
            <div class="bottomNav_phone_div">
                <div>
                    <div>
                        <div @click="toIndex">网站首页</div>
                        <div @click="toCompanyIndex">公司介绍</div>
                        <div @click="toProductIndex">产品中心</div>
                        <div @click="toNewsIndex">新闻动态</div>
                        <div @click="toContactUs">联系我们</div>
                    </div>
                    <div>
                        <div>地址：{{companyInfo.address}}</div>
                        <div>电话：{{companyInfo.phone}}</div>
                        <div>邮箱：{{companyInfo.email}}</div>
                        <div><img :src="companyInfo.qrCode" /></div>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from '@/utils/request.js';
    export default {
        name: 'TopNav',
        data() {
            return {
                phone_navMenu: false,
                navItems: [],
                // navItems: [
                //     { name: '网站首页', path: '/' },
                //     {
                //         name: '公司介绍',
                //         path: '/companyIndex',
                //         subItems: [
                //             { name: '公司简介', path: '/companyIndex', routeId: 'intro' },
                //             { name: '荣誉资质', path: '/companyIndex', routeId: 'honor' },
                //             { name: '未来愿景', path: '/companyIndex', routeId: 'vision' }
                //         ]
                //     },
                //     {
                //         name: '产品中心',
                //         path: '/productIndex',
                //         subItems: [
                //             { name: '射频组件', path: 'productIndex', routeId: 'product1' },
                //             { name: '天线组件', path: 'productIndex', routeId: 'product2' },
                //             { name: '车载业务', path: 'productIndex', routeId: 'product3' },
                //             { name: '家电照明', path: 'productIndex', routeId: 'product4' }
                //         ]
                //     },
                //     {
                //         name: '新闻动态',
                //         path: '/newsIndex',
                //         subItems: [
                //             { name: '公司新闻', path: '/newsIndex', routeId: 'news1' },
                //             { name: '行业动态', path: '/newsIndex', routeId: 'news2' },
                //             { name: '员工生活', path: '/newsIndex', routeId: 'news3' }
                //         ]
                //     },
                //     { name: '联系我们', path: '/contactUs' }
                // ],
                showSub: [],  // 控制二级菜单显示
                activeIndex: 0,  // 当前选中的一级导航索引
                companyInfo: {}, // 用于存储公司信息
                subItemsData: false

            }
        },


        watch: {
            $route(to) {
                const currentPath = to.path;
                this.navItems.forEach((item, index) => {
                    if (item.path === currentPath || (item.children && item.children.some(sub => sub.path === currentPath))) {
                        this.activeIndex = index;
                    }
                });
            }
        },
        mounted() { // 根据当前路由路径设置activeIndex
            const currentPath = this.$route.path;
            this.navItems.forEach((item, index) => {
                if (item.path === currentPath || (item.children && item.children.some(sub => sub.path === currentPath))) {
                    this.activeIndex = index;
                }
            });
            this.getInfo();
            axios.get('/Web/showHead')
                .then(response => {
                    this.navItems = response.data;
                }).catch(error => {
                    // 请求失败，提示错误信息
                    console.error('获取公司信息失败：', error);
                    // 这里可以加入其他错误处理逻辑，例如提示用户
                });
        },
        onLoad() {
        },
        methods: {
            toIndex() {
                this.$router.push('/');
            },
            toCompanyIndex() {
                this.$router.push('/companyIndex');
            },
            toProductIndex() {
                this.$router.push('/productIndex');
            },
            toNewsIndex() {
                this.$router.push('/newsIndex');
            },
            toContactUs() {
                this.$router.push('/contactUs');
            },
            handleSelect(key, keyPath) {
                console.log('select', key, keyPath);
            },
            goTo(path) {
                this.$router.push({ path });
                this.phone_navMenu = false;
            },
            setSubItemName(routeId, path) {
                this.$router.push({
                    path: path, // 保持当前路径
                    query: { routeId } // 添加查询参数
                });
            },
            showSubMenu(index) {
                this.$set(this.showSub, index, true);
            },
            hideSubMenu(index) {
                this.$set(this.showSub, index, false);
            },
            setActiveIndex(index) {
                this.activeIndex = index;
            },
            getInfo() {
                axios.get('/Web/showWeb')
                    .then(response => {
                        this.companyInfo = response.data;
                    }).catch(error => {
                        // 请求失败，提示错误信息
                        console.error('获取公司信息失败：', error);
                        // 这里可以加入其他错误处理逻辑，例如提示用户
                    });
            },
            goToIndustrialRecord() {
                // window.location.href = 'https://beian.miit.gov.cn';
                // window.open('https://beian.miit.gov.cn', '_blank');
                const link = document.createElement('a');
                link.href = 'https://beian.miit.gov.cn';
                link.target = '_blank';
                link.click();
            }
        },
    };
</script>

<style scoped>
    .topNav_pc_div {
        display: flex;
        align-items: center;
        padding: 0vw 9vw;
        padding-right: 26vw;
        justify-content: space-between;
        background-color: #f5f5f5;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        opacity: 1;
    }

    .topNav_pc_div>div:nth-child(1) {
        width: 8vw;
    }

    .navbar {
        /* padding: 10px 20px; */
        padding-top: 2vw;
        width: 50vw;
    }

    .navbar ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;

    }

    .navbar>ul>li {
        position: relative;
        margin-right: 20px;
        font-size: 1vw;
        font-weight: 400;
        padding-bottom: 2vw;
    }

    .navbar a {
        text-decoration: none;
        color: #333333;
        padding: 5px 10px;
        transition: color 0.3s ease;
    }

    .navbar a:hover {
        color: #18427E;
    }

    .navbar a.active {
        color: #18427E;
    }

    .navbar ul ul {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        display: flex;
        flex-direction: column;
        padding: 1vw 0vw;
        font-size: 0.9vw;
        z-index: 999;
    }

    .navbar ul ul li {
        padding: 10px;
        white-space: nowrap;
    }

    .navbar ul ul a {
        padding: 5px 10px;
    }

    .navbar ul li:hover>ul {
        opacity: 1;
        visibility: visible;
    }

    .bottomNav_pc_div {
        background-color: #2F5097;
        border-radius: 0.4vw;
    }

    .bottomNav_pc_div>div:nth-child(1) {
        padding: 1.5vw 8vw 1vw 16vw;
        display: flex;
        color: #E5E1E0;
        font-size: 0.8vw;
    }

    .bottomNav_pc_div>div:nth-child(1) a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1vw;
        font-weight: 600;
    }

    .bottomNav_pc_div>div:nth-child(1)>div {
        margin: 1vw 1.5vw;
    }

    .bottomNav_pc_div>div:nth-child(1)>div:nth-child(5) {
        display: none;
    }

    .bottomNav_pc_div>div:nth-child(1)>div:nth-child(6) {
        margin-top: 0.5vw;
    }

    .bottomNav_pc_div>div:nth-child(1)>div:nth-child(6)>div:nth-child(1) {
        color: #ffffff;
        font-size: 1vw;
        font-weight: 600;
    }

    .bottomNav_pc_div>div:nth-child(1)>div>div {
        margin: 0.5vw 0vw;
    }

    .bottomNav_pc_div>div:nth-child(1)>div:last-child {
        width: 7vw;
        height: 7vw;
        margin-left: 15vw;
        margin-top: 2vw;
    }

    .bottomNav_pc_div>div:nth-child(2) {
        display: flex;
        align-items: center;
        padding: 0vw 13vw 2vw 13vw;
        color: #C7C2C2;
        justify-content: space-between;
        font-size: 0.8vw;
    }

    .topNav_phone_div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0vw 9vw;
        color: #81B5E4;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        opacity: 1;
        background-color: #f5f5f5;
    }

    .topNav_phone_div>div {
        width: 20vw;
        font-size: 6vw;
    }

    .bottomNav_phone_div {
        background-color: #2F5097;
        border-radius: 0.4vw;
        color: #E5E1E0;
        font-size: 2vw;
    }

    .bottomNav_phone_div>div:nth-child(1) {
        padding: 3vw 3vw 0.3vw 3vw;
        display: flex;
        justify-content: space-between;
    }

    .bottomNav_phone_div>div:nth-child(2) {
        background-color: rgba(0, 0, 0, 0.4);
        height: 5vh;
    }

    .bottomNav_phone_div>div:nth-child(1)>div:nth-child(2)>div:last-child {
        width: 15vw;
        height: 15vw;
        float: right;
        margin-top: 0.3vw;
    }

    .bottomNav_phone_div>div:nth-child(1)>div>div {
        margin-top: 2vw;
    }

    /* 默认情况下，显示PC端导航 */
    .topNav_pc {
        display: block;
    }

    .topNav_phone {
        display: none;
    }

    .bottomNav_pc {
        display: block;
    }

    .bottomNav_phone {
        display: none;
    }

    /* 当屏幕宽度小于或等于750px时，应用以下样式 */
    @media (max-width: 750px) {
        .topNav_pc {
            display: none;
        }

        .topNav_phone {
            display: block;
        }

        .bottomNav_pc {
            display: none;
        }

        .bottomNav_phone {
            display: block;
        }

        ul {
            background-color: rgba(0, 0, 0, 0) !important;
        }

        .phoneMenu_item /deep/ .el-submenu__title {
            color: white;
        }

        .phoneMenu_item /deep/ .el-submenu__title i {
            color: white;
        }

        .phoneMenu_item /deep/ .el-submenu__title:hover {
            background: none;
        }

        .phoneMenu_item /deep/ .el-menu {
            background: none;
        }

        .phoneMenu_item /deep/ .el-menu .el-menu-item {
            color: white;
        }
    }

    .showMenu {
        background-color: #81B5E4;
        padding: 3vw;
        position: fixed;
        top: 4vh;
        z-index: 9;
        width: 100%;
    }

    .showMenu>div {
        margin-bottom: 1vw;
    }

    .showMenu>div i {
        color: white;
        margin-left: 1vw;
    }

    .showMenu a {
        text-decoration: none;
        color: white;
    }
</style>