import axios from 'axios';

const instance = axios.create({
  // 移除错误的字符，正确设置 API 基础路径
  baseURL: 'https://ytpartron.cn:97', 
  timeout: 5000
});

// 添加请求拦截器
instance.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    // 可以在这里添加 token 等请求头信息
    // 例如：config.headers['Authorization'] = `Bearer ${yourToken}`;
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    // 通常在这里处理响应数据，例如提取 response.data
    return response.data;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default instance;