<template>
  <div id="app">
    <TopNav />
    <!-- <ReturnTop /> -->
    <div class="appToTop" @click="toTop"><img src="../src/assets/index/top.png" /></div>
  </div>
</template>

<style>
  .appToTop {
    position: fixed;
    bottom: 6vw;
    right: 1vw;
    width: 2vw;
    height: 2vw;
    z-index: 999999999999999999999999999999999999;
  }

  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  #app {
    /* height: 99.9vh; */
    /* border: 1px solid blue; */
    overflow: auto;
    background-color: #ffffff;
  }

  img {
    width: 100%;
    height: 100%;
  }

  input {
    border: none;
    outline: none;
    padding: 0.2vw 0.6vw;
  }

  ::-webkit-scrollbar {
    display: none;
    width: 8px;
    /* 对于垂直滚动条，设置宽度 */
  }

  ::-webkit-scrollbar-thumb {
    /* background: #011528; */
    background: rgba(0, 0, 0, 0);
    /* 滑块颜色 */
    border-radius: 50px;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    /* background: #d9d9da ; */
    background: rgba(0, 0, 0, 0);
    /* 轨道颜色 */
  }

  /* 滑块hover效果 */
  ::-webkit-scrollbar-thumb:hover {
    background: #011528;
    /* 滑块hover颜色 */
  }

  .root {
    width: 100%;
    margin-top: 5.3vw;
  }
  @media (max-width: 750px) {
    .appToTop{
width: 6vw;
height:6vw;
    }
  }
</style>

<script>
  import TopNav from '@/components/topNav.vue'; // 确保路径正确
  // 导入Vue Router
  import router from '@/router'; // 确保路径正确
  // import ReturnTop from '@/components/returnTop.vue'; // 确保路径正确

  export default {
    name: 'App',
    components: {
      TopNav, // 确保组件在这里全局注册
      // ReturnTop
    },
    created() {
      // 使用全局前置守卫
      router.beforeEach((to, from, next) => {
        this.toTop(); // 每次路由切换时调用toTop方法
        next();
      });
    },
    methods: {
      toTop() {
        window.scrollTo({
          top: 0, // 滚动到页面顶部
          behavior: 'smooth' // 平滑滚动
        });
      },
    },

  };
</script>